.CellInput {
    border: none;
    outline: none;
    resize: none;
    display: inline-block;
    position: relative;
    background-color: white;
    /* outline-color: #888; */
    top: 0;
    left: 0;
    width: 100%;
    margin: 2px;
    padding: 0px;
    font-size: 13px;
    /* padding: 5px 5px; */
}

input.CellInput {
    height: 22px;
}

textarea.CellInput {
    height: calc(100% - 10px);
}

.CellInput:focus {
    margin: 0px;
    border: 2px solid darkgray;
    outline: none;
    /* outline: 2px solid darkgray; */
}