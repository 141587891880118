.Header {
    position: absolute;
    display: block;
    top: 0;
    height: 40px;
    left: 0;
    right: 0;
    color: white;
    width: 100%;
    z-index: 10;
}

.Header .MenuIcon {
    position: absolute;
    top: 0;
    vertical-align: top;
    left: 0;
    color: white;
}

.Header .HomeIcon {
    position: absolute;
    top: 0;
    vertical-align: top;
    left: 50px;
    color: white;
}

.Header .Title {
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    text-align: center;
    font-size: 30px;
}

.Header .ExitToAppIcon {
    top: 0;
    vertical-align: top;
    color: white;
    display: inline-block;
}

.Header .SettingsIcon {
    top: 0;
    vertical-align: top;
    color: white;
    display: inline-block;
}

.Header .Info {
    position: absolute;
    display: block;
    white-space: nowrap;
    height: 40px;
    top: 0;
    min-width: 80px;
    color: white;
    right: 10px;
    z-index: 10;
}

.Header .UserName {
    display: inline-block;
    position: relative;
    top: 11px;
    vertical-align: top;
}

.Header #ProgressIndicator {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top: 10px;
    visibility: hidden;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .Header {
        position: fixed;
        height: 30px;
    }

    .Header .Title {
        font-size: 15px;
        margin-top: 5px;
    }

    .Header .MenuIcon {
        padding: 4px;
    }

    .Header .HomeIcon {
        left: 30px;
        padding: 4px;
    }

    .Header .HomeIcon {
        left: 30px;
        padding: 4px;
    }

    .Header .UserName {
        display: none;
    }

    .Header .SettingsIcon {
        padding: 4px;
    }

    .Header .ExitToAppIcon {
        padding: 4px;
    }

    .Header .Info {
        right: 0;
        height: 30px;
    }

    .Header #ProgressIndicator {
        margin-top: 0px;
        width: 30px;
        height: 30px;
    }
}