.ConstReportList {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.ConstReportList .list {
    overflow-y: scroll;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ConstReportList .submitDate {
    display: flex;
    width: 100%;
    padding-top: 5px;
    text-align: right;
    justify-content: flex-end;
}

.ConstReportList .outlinePanel {
    padding: 5px;
    position: relative;
    height: auto;
    background-color: white;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .ConstReportList .list {
        overflow-x: hidden;
    }

    .ConstReportList .submitDate {
        justify-content: flex-start;
    }

    .ConstReportList .list {
        overflow-x: hidden;
    }

    .ConstReportList .outlinePanel {
        padding: 0px;
    }

    .ConstReportList .ConstReport2 {
        width: calc(100% - 10px);
    }
}