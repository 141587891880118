.TimeInput {
  display: inline-block;
  text-align: center;
  outline: none;
  border: none;
  width: 50px;
  height: 22px;
  padding: 0px;
  margin: 2px;
  font-size: 13px;
}

.TimeInput:focus {
  outline: 1px solid darkgray;
}