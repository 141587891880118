.Setting{
    position: absolute;
    display: block;
    right: 0px;
    width: 380px;
    z-index: 9;
}

.Setting .InfoBox .Title{
    width: 120px;
}

.Setting .InfoBox .Value{
    width: 230px;
}