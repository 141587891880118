.ConstListRow {
  background-color: white;
}

.ConstListRow:hover {
  background-color: #efe;
}

.ConstListRow .constId{
  white-space: nowrap;
}