.ReportBody {
    position: absolute;
    display: flex;
    top: 0px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: auto;
    box-sizing: border-box;
    overflow: auto;
    flex-wrap: nowrap;
    flex-direction: column;
}

.ReportBody .outline {
    margin: 10px;
    position: relative;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .ReportBody .outline {
        margin: 5px;
    }
}