.LoadingVehicle {
  width: 50%;
}

.LoadingVehicle .dataList {
  width: 100%;
  table-layout: fixed;
}

.LoadingVehicle .scrollHead {
  background-color: #ddd;
}

.LoadingVehicle .scrollBody {
  background-color: #ddf;
}

.LoadingVehicle .vehicleType {
  width: 200px;
}

.LoadingVehicle .vehicleTypeInput {
  width: 150px;
}

.LoadingVehicle .vehicleCount {
  width: 50px;
}

.LoadingVehicle .vehicleCountInput {
  width: 40px;
}

.LoadingVehicle .materialName {
  width: 200px;
}

.LoadingVehicle .materialNameInput {
  width: 150px;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .LoadingVehicle {
    width: 100%;
  }

  .LoadingVehicle .vehicleType {
    width: 100px;
  }

  .LoadingVehicle .vehicleCount {
    width: 30px;
  }

  .LoadingVehicle .materialName {
    width: 100px;
  }
}