@media screen and (min-width: 481px) {
  .DailySafetyCheck .DalySafetyCheckLabel {
    width: 70px;
    margin-left: 5px;
  }

  .DailySafetyCheck .constVertical {
    border-top: 0px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .DailySafetyCheck .DalySafetyCheckLabel {
    width: 60px;
    margin-left: 3px;
    margin-top: 3px;
  }
}