.DateInput {
  border: none;
  outline: none;
  resize: none;
  display: inline-block;
  position: relative;
  /* outline-color: #888; */
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px;
  font-size: 13px;
  /* padding: 5px 5px; */
  text-align: right;
}

/* Chrome等で、input type=numberの際の入力補助機能を非表示にする */
.DateInput::-webkit-outer-spin-button,
.DateInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefoxで、input type=numberの際の入力補助機能を非表示にする */
.DateInput[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}