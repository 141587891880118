.ConstListController {
  display: block;
}

.ConstListController .constHeader {
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .ConstListController .constHeader {
    width: 100%;
  }
}

.ResultManageFooter a:-webkit-any-link {
  color: white;
  text-decoration: none;
}