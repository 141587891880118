.UserChoicer {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    /* margin: 3px; */
}

.UserChoicer .name input {
    border-style: none;
    outline-style: none;
    margin: 0px;
    padding: 0px;
}