.RiskPrediction{
  position: relative;
  display: block;
  table-layout: fixed;
  width: 100%;
  margin: 5px;
}

.RiskPrediction td {
  padding: 2px;
}

.RiskPrediction thead {
  border-bottom: 1px solid gray;
  color: gray;
}

.RiskPrediction tbody {
  border-bottom: 1px solid gray;
  color: gray;
}

.RiskPrediction td,
.RiskPrediction th {
  table-layout: fixed;
}

.RiskPrediction .number{
  width: 40px;
}

.RiskPrediction .where{
  display: flex;
  flex-direction: row;
}

.RiskPrediction .riskPText{
  width: 180px;
}

.RiskPrediction .evaluation{
  display: flex;
  flex-direction: row;
}

.RiskPrediction .evaluation span{
  white-space: nowrap;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .RiskPrediction .riskPText{
    width: 155px;
  }
}

