.ConstSchedule {
  position: relative;
  display: block;
  margin: 2px;
  top: 0;
  bottom: 10px;
  width: 99%;
  overflow-y: auto;
  overflow-x: auto;
}

.ConstSchedule .AddConstType {
  font-size: 14px;
  padding: 5px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border: 0 none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: opacity 300ms linear;
  opacity: 0.2;
}

.ConstSchedule .AddConstType:hover {
  opacity: 0.6;
}

.ConstSchedule .Gantt {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: auto;
}

.ConstSchedule>div {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.ConstSchedule .ScheduleNameCell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  min-width: 250px;
  max-width: 250px;
}

.ConstSchedule .ScheduleDateCell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  min-width: 110px;
  max-width: 110px;
}