.InfoBox {
    position: relative;
    display: inline-flex;
    min-width: 300px;
    min-height: 30px;
    max-height: 30px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid #888;
}

.InfoBox .Title {
    /* 左上と右上を丸める */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 1px;
    min-width: 100px;
    display: flex;
    align-items: center;
    /* 縦方向中央揃え */
    justify-content: center;
    /* 横方向中央揃え */
    border-right: 1px solid white;
    background-color: #ddd;
    white-space: nowrap;
    font-size: 13px;
    flex-grow: 0;
}

.InfoBox .Value {
    padding-left: 3px;
    padding-top: 3px;
    min-width: 200px;
    display: inline;
    vertical-align: bottom;
    font-size: 14px;
    background-color: white;
    /* text-align: center; */
    flex-grow: 1;
    font-size: 13px;
    line-height: 2em;
}

.InfoBox .Value input {
    font-size: 14px;
}