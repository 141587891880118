.Members .memberContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Members .header {
  text-align: center;
  background-color: #ddd;
  height: 6mm;
  min-height: 6mm;
  max-height: 6mm;
}

.Members .constMembers {
  width: 140px;
  height: 6mm;
  min-height: 6mm;
  max-height: 6mm;
}

.Members .userItem {
  margin: 5px;
  display: flex;
  background-color: white;
  flex-direction: column;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .Members .memberContainer {
    display: flex;
    flex-direction: column;
  }

  .Members .userItem {
    flex-direction: row;
  }

  .Members .constMembers {
    width: 160px;
  }

  .Members .header {
    text-align: right;
    background-color: #ddf;
    text-align: justify;
    text-align-last: justify;
    width: 100px;
  }

  .Members .header::after {
    content: "：";
  }
}