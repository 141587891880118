.Book .slick-dots {
  text-align: center;
}
/* ドットはデフォルトでは非表示化しておく */
.Book .slick-dots li {
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
}
.Book .slick-dots li > * {
  display: none;
}
/* 表示させるドット：最初、最後、選択中、visibleクラスを付与したものに限定 */
.Book .slick-dots li:first-child > *,
.Book .slick-dots li:last-child > *,
.Book .slick-dots li.slick-active > *,
.Book .slick-dots li > .visible
{
    display: inline-block;
}

.Book .slick-dots li:first-child > button:before,
.Book .slick-dots li:last-child > button:before
{
  color: red;
}

.Book .slick-dots li.slick-active:first-child > button:before,
.Book .slick-dots li.slick-active:last-child > button:before
{
  color: black;
}


/* .slick-dots-container {
  width: 5.5rem;
  overflow: hidden;
  display: block;
  padding: 0;
  margin: 0.625rem auto;
  height: 0.875rem;
  position: relative;
}

.slick-dots-container > ul {
  padding: 0;
  display: flex;
  transition: all 0.25s;
  position: relative;
  margin: 0;
  list-style: none;
  transform: translateX(0);
  align-items: center;
  bottom: unset;
  height: 100%;
}

.slick-dots-container > ul li {
  width: 0.625rem;
  height: 0.625rem;
  margin: 0 0.25rem;
  background-color: #efd5d5;
  border: none;
  border-radius: 50%;
}

.slick-dots-container > ul li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.3125rem;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.slick-dots-container > ul li.p-small-1,
.slick-dots-container > ul li.n-small-1 {
  transform: scale(0.8);
}

.slick-dots-container > ul li.slick-active {
  transform: scale(1.3);
  transform-origin: center;
  background: #3498db;
}

.slick-dots li button:before {
  display: none;
}
 */
