.MorningMeeting {
  margin-top: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Chrome等で、input type=numberの際の入力補助機能を非表示にする */
.MorningMeeting input::-webkit-outer-spin-button,
.MorningMeeting input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .MorningMeeting .LimitedTextarea {
    height: 50px !important;
  }
}