.ReportList {
    display: block;
}

.ReportList .ReportListListControl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-color: darkgray;
    box-sizing: border-box;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 10px;
}

.ReportList .ReportListReportControl {
    position: relative;
    top: 0;
    right: -100%;
    min-height: 100%;
    width: 100%;
    display: block;
    background-color: darkgray;
    box-sizing: border-box;
    flex-wrap: nowrap;
    flex-direction: column;
}

.ReportList .ReportListListControl table {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .ReportList .ReportListListControl {
        margin: 5px;
    }
}