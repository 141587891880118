.ConstListRow .inp input::-webkit-outer-spin-button,
.ConstListRow .inp input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ConstList .ConstListRow .inp {
  background-color: #bbddbb;
}

.ConstList .focus {
  background-color: #ddf !important;
}

.ConstList .focus .inp {
  background-color: #bbd !important;
}