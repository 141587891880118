.ConstReports {
  position: absolute;
  display: flex;
  width: 100%;
  top: 0px;
  bottom: 0px;
  padding: 5px;
  background-color: white;
}

.ConstReports .Calendar {
  overflow: hidden;
  flex-grow: 0;
}

.ConstReports .Reports {
  position: relative;
  overflow-y: scroll;
  flex-grow: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  min-width: 960px;
  padding-right: 20px;
}

.ConstReports .UnavailableDate {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: white;
}

.ConstReports .react-calendar__month-view__days__day--weekend {
  color: red;
}