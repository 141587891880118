.Good{
    position: relative;
    margin: 5px 0px 5px 10px;
    display: flex;
    align-items: center;
    width: auto;
    background: rgba(0,0,0,0.0);
    z-index: 5;
}

.Good .ThumbOn{
    font-size: 15px;
    color: #f50057;

}
.Good .ThumbOff{
    font-size: 15px;
    color: lightgray;
}

.Good .ThumbReadOnly{
    font-size: 15px;
    color: gray;
}

.Good .GoodMember{
    display: block;
    position: absolute;
    left: 10px;
    bottom: -10px;
    padding: 5px;
    font-size: 12px;
    background-color: lightgreen;
}

.Good .ThumbOff:hover{
    color: gray;
}
