.ConstReport {
    position: relative;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* overflow-x: auto; */
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: white;
}

.ConstReport .miniLabel {
    font-size: 11px;
    line-height: 24px;
    vertical-align: bottom;
}

.ConstReport .constRow {
    position: relative;
    width: 937px;
    display: flex;
    border-top: 3px solid white;
}

.ConstReport .constHeader {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
}

.ConstReport .constTitle {
    padding: 3px;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    display: flex;
    align-items: center;
    /* 縦方向中央揃え */
    border-right: 1px solid white;
    background-color: #ddd;
    text-align: left;
}

.ConstReport .constCell {
    padding: 5px;
    display: flex;
    align-items: center;
    /* 縦方向中央揃え */
}

.ConstReport .today {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    display: flex;
    align-items: flex-start;
    /* 縦方向上揃え */
    background-color: #ddf;
}

.ConstReport .tomorrow {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    display: flex;
    align-items: flex-start;
    /* 縦方向上揃え */
    background-color: #fdd;
}

.ConstReport .member {
    display: block;
    position: relative;
    width: 100%;
    background-color: #ddf;
    padding: 0px;
}

.ConstReport .constInfo {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    /* 縦方向上揃え */
    background-color: #ddf;
}

.ConstReport .constPhoto {
    width: 100%;
    display: flex;
    min-height: 30px;
    flex-direction: column;
    border: 4px solid #ddf;
}

.ConstReport span {
    color: #666;
}

.ConstReport .PhotoList span {
    color: white;
}

.ConstReport .PhotoList {
    display: inline-block;
    overflow-x: hidden;
    overflow-y: auto;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .ConstReport .constRow {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow: clip;
    }

    .ConstReport .constTitle {
        width: auto;
        min-width: 10%;
        max-width: 100%;
    }

    .ConstReport .today {
        width: calc(100% - 10px);
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px);
    }

    .ConstReport .tomorrow {
        width: calc(100% - 10px);
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px);
    }

    .ConstReport .constPhoto {
        min-height: 100px;
        width: calc(100% - 20px);
        min-width: calc(100% - 20px);
        max-width: calc(100% - 20px);
    }

    .ConstReport .CellInput {
        max-width: calc(100vw - 30px);
    }
}