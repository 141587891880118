.ConstReport2 {
  position: relative;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 1000px;
  /* overflow-x: auto; */
  flex-wrap: wrap;
  background-color: white;
}

.ConstReport2 .constRow {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  border-top: 3px solid white;
}

.ConstReport2 .constVertical {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
  border-top: 3px solid white;
  background-color: #ddf;
}

.ConstReport2 .constHeader {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}

.ConstReport2 .constTitle {
  position: relative;
  padding: 3px;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  display: flex;
  align-items: center;
  border-right: 1px solid white;
  background-color: #ddd;
  text-align: left;
}

.ConstReport2 .constCell {
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: #ddf;
}

.ConstReport2 .constInfo {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.ConstReport2 .constVertical .constTitle {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.ConstReport2 .constVertical .constCell {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.ConstReport2 .DateGroup {
  display: flex;
  flex-direction: column;
}

.ConstReport2 .DateTime {
  display: inline-block;
  margin: 2px;
}

/*
* PC用定義
*/
@media screen and (min-width: 481px) {
  .ConstReport2 {
    width: 925px;
    padding: 5px;
  }

  .ConstReport2 .Terms {
    min-width: 100%;
    flex-grow: 1;
  }

  .ConstReport2 .MonthlyTargetVolume {
    min-width: 550px;
    flex-grow: 1;
  }

}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .ConstReport2 {
    padding: 5px;
  }

  .ConstReport2 .constRow {
    flex-grow: 1;
    width: 100%;
  }

  .ConstReport2 .constCell {
    width: 100%;
  }

  .ConstReport2 .DateGroup {
    width: 100%;
  }

  .ConstReport2 .crCtrl {
    display: block;
  }
}