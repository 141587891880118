.App {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    overscroll-behavior: none;
}


.OverflowText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.OverflowText:hover {
    white-space: inherit;
    overflow: visible;
    text-overflow: inherit;
    z-index: 10;
}