.ProgressStatus{
    display: flex;
    align-items: center;
    position: relative;
}

.ProgressStatus .ProgressSelector{
    cursor: pointer;
}

.ProgressStatus .ProgressSelector option{
    cursor: pointer;
}