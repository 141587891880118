.ConstReportComments{
    position : relative;
    background-color:white;
    border: 1px solid lightgrey;
    margin: 10px 5px 5px 5px;
    width: 815px;
}

.ConstReportComments .message{
    display: block;
    margin: 0px;
    max-width: 500px;
    word-break: break-all;
    white-space: pre-wrap;
}

.ConstReportComments .SendButton{
    opacity: 0.3;
}

.ConstReportComments .SendButton:hover{
    opacity: 0.8;
}
@media screen and (max-width: 480px) and (orientation:portrait) {
    .ConstReportComments{
        width: calc(100% - 10px);
    }
}
