#AllPhotos .SelectPhotoByDate {
  position: relative;
  display: block;
  cursor: pointer;
}

#AllPhotos .SelectPhotoByDate .HoverText {
  position: absolute;
  display: block;
  text-align: center;
  font-size: 12px;
  top: 0px;
  width: 100%;
  color: rgba(0, 0, 0, 0)
}

#AllPhotos .SelectPhotoByDate:hover {
  background-color: #ddd;
}

#AllPhotos .SelectPhotoByDate:hover .HoverText {
  color: rgba(0, 0, 0, 0.5);
}

.AllPhotos a:-webkit-any-link {
  color: rgba(0, 0, 0, 0.54);
}