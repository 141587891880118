.UserDialog table {
    position: relative;
    display: block;
    font-family: Meiryo,
        "Hiragino Kaku Gothic Pro",
        "MS PGothic",
        Verdana,
        sans-serif;
    font-size: 12px;
    color: black;
    table-layout: fixed;
    width: 900px;
    background-color: white;
    border: 0px none #fff;
}

.UserDialog .dataList {
    width: 100%;
}

.UserDialog td {
    background-color: white;
    padding: 2px
}

.UserDialog thead {
    border-bottom: 2px solid black
}

.UserDialog .scrollHead,
.UserDialog .scrollBody {
    display: block;
}

.UserDialog .scrollBody {
    height: 360px;
    overflow-y: scroll;
}

.UserDialog td,
.UserDialog th {
    table-layout: fixed;
}

.UserDialog .choise {
    width: 70px;
}

.UserDialog .userId {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.UserDialog .userId div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.UserDialog .name {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.UserDialog .name div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.UserDialog .groupName {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.UserDialog .groupName div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.UserDialog .mail {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
}

.UserDialog .mail div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .UserDialog .dataList {
        height: 250px;
    }

    .UserDialog .name {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
    }

    .UserDialog .groupName {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
    }
}