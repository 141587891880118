.ExpandControl {
  margin: 0;
  padding: 0;
  border: 1 solid #ddd;
  background-color: #ddd;
  width: 15px;
  height: 15px;
  cursor: pointer;
  outline: none;
  font-size: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}