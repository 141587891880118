.SafetyInspectionV2 {
  width: 100%;
}

.SafetyInspectionV2 table {
  width: 100%;
}

.SafetyInspectionV2 .safetyInspectionRow {
  height: 40px;
}

.SafetyInspectionV2 .categoryName {
  background-color: #ddd;
  writing-mode: vertical-rl;
  text-align: center;
  width: 40px;
}

.SafetyInspectionV2 .categoryName span {
  white-space: pre;
  writing-mode: vertical-rl;
  display: inline-block;
}

.SafetyInspectionV2 .description {
  background-color: #ddd;
}

.SafetyInspectionV2 .status {
  width: 40px;
  min-width: 40px;
  border: 1px solid #ddf;
  text-align: center;
}

.SafetyInspectionV2 .correctiveAction {
  position: relative;
  border: 1px solid #ddf;
  width: 120px;
}

.SafetyInspectionV2 .correctiveAction textarea {
  position: absolute;
  inset: 0;
  outline: none;
  border: none;
}

/*
.SafetyInspectionV2 .disabled {
  background-image: linear-gradient(to top left, transparent 49%, #aaf 49%, #aaf 51%, transparent 51%, transparent);
  margin: 2px;
}
*/
.SafetyInspectionV2 .disabled::after {
  content: "ー";
}

.SafetyInspectionV2 .ok::after {
  content: "◯";
}

.SafetyInspectionV2 .ng::after {
  content: "×";
}