.VisitorInfo .dataList {
  width: 100%;
  table-layout: fixed;
}

.VisitorInfo .scrollHead {
  background-color: #ddd;
}

.VisitorInfo .scrollBody {
  background-color: #ddf;
}

.VisitorInfo .companyName {
  width: 120px;
}

.VisitorInfo .companyNameInput {
  width: calc(100% - 5px);
}

.VisitorInfo .name {
  width: 120px;
}

.VisitorInfo .nameInput {
  width: calc(100% - 5px);
}

.VisitorInfo .purposeOfVisit {
  width: 150px;
}

.VisitorInfo .purposeOfVisitInput {
  width: calc(100% - 5px);
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .VisitorInfo .companyName {
    width: 100px;
  }
}