.ConstList {
  display: block;
  width: 100%;
  height: calc(100% - 60px);
}

.ConstList table {
  overflow: scroll;
  height: 100%;
  width: 100%;
}

.ConstList thead .rh0,
.ConstList thead .rh1 {
  z-index: 3;
}

.ConstList .rh0 {
  position: sticky;
  left: 0px;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  z-index: 1;
  padding: 0;
  background-color: inherit;
}

.ConstList .rh1 {
  position: sticky;
  left: 100px;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  border-right: 1px solid #ccc;
  z-index: 1;
  padding: 0;
  background-color: inherit;
}

.ConstList .constTerm {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
}

.ConstList .price {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  text-align: right;
}

.ConstList .rate {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  text-align: right;
}

.ConstList tr:nth-child(even) {
  background-color: white;
}

.ConstList tr:nth-child(odd) {
  background-color: #eee;
}

.ConstList th,
.ConstList td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.ConstList .m {
  text-align: right;
}