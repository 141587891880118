.PhotoPanel {
  width: 100%;
  background-color: blue;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.Blank {
  height: 300px;
  width: 500px;
  text-align: center;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .Blank {
    width: 100%;
  }

  .PhotoPanel {
    padding-top: 40px;
    padding-left: 10px;
  }
}