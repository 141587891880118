.ConstSelector {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.ConstSelector .constId {
    width: 70px;
    margin-right: 5px;
    flex-grow: 0;
    vertical-align: middle;
}

.ConstSelector .name {
    text-align: left;
    width: calc(100% - 100px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 100;
    line-height: 24px;
    vertical-align: middle;
}

.ConstSelector .icon {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 0;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .ConstSelector {
        max-width: 410px;
        width: 390px;
    }
}