.WorkReportHistory{
    width: 850px;
    position : relative;
    background-color:white;
    border: 1px solid lightgrey;
    z-index: 5;
    margin: 10px 5px 5px 5px;
}

.WorkReportHistory .message{
    font-size: 14px;
    display: block;
    margin: 0px;
    max-width: 500px;
    word-break: break-all;
    white-space: pre-wrap;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .WorkReportHistory{
        width: calc(100% - 10px);
    }
}

