.Dedura {
  position: relative;
  display: block;
  table-layout: fixed;
  width: 100%;
  margin: 5px 5px 5px 0px;
}

.Dedura td {
  padding: 2px;
}

.Dedura thead {
  border-bottom: 1px solid gray;
  color: gray;
}

.Dedura .scrollHead,
.Dedura .scrollBody {
  display: block;
}

.Dedura td,
.Dedura th {
  table-layout: fixed;
}

.Dedura .contract {
  width: 60px;
}

.Dedura .dedura {
  width: 100px;
}

.Dedura .company {
  width: 170px;
}

.Dedura .count {
  width: 30px;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .Dedura .contract {
    width: 60px;
  }

  .Dedura .dedura {
    width: 90px;
  }

  .Dedura .company {
    width: 160px;
  }

  .Dedura .count {
    width: 30px;
  }
}