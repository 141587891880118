.LimitedTextarea {
  overflow: hidden;
  border: none;
  outline: none;
  width: calc(100% - 10px);
  resize: none;
  font-size: 13px;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .LimitedTextarea {
    max-width: calc(100% - 10px);
    min-width: calc(100% - 10px);
  }
}