.SideMenu {
    position: absolute;
    display: block;
    background-color: white;
    top: 40px;
    bottom: 0;
    max-height: 100vh;
    width: 200px;
    z-index: 3000;
    overflow-x: hidden;
    overflow-y:auto;
}

.SideMenu .HideMenu {
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid lightgrey;
}