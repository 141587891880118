.Actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  opacity: 0.1;
  height: 30px;
  cursor: pointer;
  font-size: 9px;
  transition: opacity 300ms linear;
}

.Actions:hover {
  opacity: 0.6;
}

.Actions button {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0 none;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .Actions button {
    opacity: 1.0;
  }
}

.Actions button:hover {
  opacity: 1.0;
}

.Actions .group-button {
  display: flex;
  align-items: center;
  height: 25px;
  width: 20px;
  /* ボタンの高さ */
}

.Actions .group-button button {
  height: 100%;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
}

.Actions .vertical-buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20px;
}

.Actions .up,
.Actions .down {
  height: 50%;
  max-height: 50%;
  width: 100%;
}

.Actions .up {
  background-image: url("/public/images/up.png");
  background-size: 100%;
}

.Actions .down {
  background-image: url("/public/images/down.png");
  background-size: 100%;
}