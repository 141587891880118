.MemberChoicer{
    display: block;
    position: relative;
    background-color:white;
    margin: 5px;
    height: 100px;
}

.MemberChoicer .editText{
    border: none;
    outline: none;
}