.MachineOperatingStatus {
  width: 50%;
}

.MachineOperatingStatus .dataList {
  width: 100%;
  table-layout: fixed;
}

.MachineOperatingStatus .scrollHead {
  background-color: #ddd;
}

.MachineOperatingStatus .scrollBody {
  background-color: #ddf;
}

.MachineOperatingStatus .vehicleType {
  width: 130px;
}

.MachineOperatingStatus .vehicleTypeInput {
  width: 120px;
}

.MachineOperatingStatus .tonnage {
  width: 70px;
}

.MachineOperatingStatus .tonnageInput {
  width: 60px;
}

.MachineOperatingStatus .vehicleCount {
  width: 40px;
}

.MachineOperatingStatus .vehicleCountInput {
  width: calc(100% - 5px);
}

.MachineOperatingStatus .description {
  width: 200px;
}

.MachineOperatingStatus .descriptionInput {
  width: 150px;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .MachineOperatingStatus {
    width: 100%;
  }

  .MachineOperatingStatus .vehicleType {
    width: 110px;
  }

  .MachineOperatingStatus .vehicleTypeInput {
    width: 105px;
  }

  .MachineOperatingStatus .tonnage {
    width: 80px;
  }

  .MachineOperatingStatus .tonnageInput {
    width: 70px;
  }

  .MachineOperatingStatus .vehicleCount {
    width: 45px;
  }

  .MachineOperatingStatus .vehicleCountInput {
    width: 40px;
  }

  .MachineOperatingStatus .description {
    width: 110px;
  }

  .MachineOperatingStatus .descriptionInput {
    width: 105px;
  }
}