.ConstInformations {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
  width: 1200px;
}

.ConstInformations .Panel {
  background-color: #eee;
  margin: 10px;
}

.ConstInformations .ProjectOverview {
  width: 40%;
  min-width: 400px;
}

.ConstInformations .ProjectLocation {
  width: 35%;
  min-width: 300px;
}

.ConstInformations .Weather {
  width: 35%;
  min-width: 300px;
}

.ConstInformations .ProjectProgress {
  width: 25%;
  min-width: 100px;
}

.ConstInformations .ProjectStatus {
  width: 100%;
  min-width: 600px;
}

.ConstInformations .ProjectPhotos {
  width: 30%;
  flex-grow: 1;
  min-width: 300px;
}