.Content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    background-color: darkgray;
    margin-left: auto;
    box-sizing: border-box;
    overscroll-behavior: none;
}