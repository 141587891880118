.ConstManagePhotoList {
  position: relative;
  display: block;
  width: calc(100% - 15px);
  height: 100%;
  top: 0px;
  bottom: 55px;
  padding: 5px;
  overflow-y: scroll;
  background-color: white;
}