.SearchCondition {
  background-color: white;
  padding: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SearchCondition .searchString {
  width: 180px;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .SearchCondition {
    display: block;
  }
}