.ConstPhoto{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 10px solid darkgray;
    background-color: darkgray;
    overflow: hidden;
    box-sizing: border-box;
}
.ConstPhoto .Const{
    position: absolute;
    display: block;
    top: 0px;
    height: 30px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #ddd;
}

.ConstPhoto .BookList{
    position: absolute;
    display: block;
    top: 35px;
    bottom: 0px;
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #ddd;
}

.ConstPhoto .BookSlider{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;
    top: 35px;
    bottom: 0px;
    left: 290px;
    width: 10px;
    overflow: hidden;
    border: 1px solid rgba(255,255,255,0.0);
    background-color: rgba(255,255,255,0.0);
    cursor: pointer;
    transition: all 200ms ease;
}

.ConstPhoto .BookSlider:hover{
    opacity: 0.6;
    border: 1px solid #ccc;
    background-color: #bbc !important;
    transition: all 400ms ease;
}

.ConstPhoto .BookList a{
    background-color: #fff;
    border-bottom: 1px solid darkgray;
    padding: 2px;
}

.ConstPhoto .BookList a:hover{
    background-color: #ccc;
}
.ConstPhoto .ListItemLink:hover{
    background-color: #ccf!important;
}

.ConstPhoto .BookEditor{
    position: absolute;
    display: block;
    overflow-x: auto;
    overflow-y: auto;
    top: 35px;
    bottom: 0px;
    left: 310px;
    right: 0px;
    background-color: #ddd;
    border: 1px solid #ccc;
    /* padding: 4px; */
}

.ConstPhoto .BookEditor .Pager{
    opacity: 0.3;
    background-color: #ccc;
}

.ConstPhoto .BookEditor .Pager:hover{
    opacity: 0.8;
}

.ConstPhoto .BookList .ListItemLink .BookListEditControl{
    opacity: 0.0;
    color: #888;
}

.ConstPhoto .BookList .ListItemLink:hover .BookListEditControl{
    opacity: 1.0;
}

.ConstPhoto .BookList .ListItemLink .RenameIcon:hover{
    color: #88f;
}

.ConstPhoto .BookList .ListItemLink .RemoveIcon:hover{
    color: #f88;
}

.ConstPhoto .PageBreakController{
    position: absolute;
    bottom: 0;
    height: 25px;
    width: 100%;
    background-color: white;
    text-align: center;
    opacity: 0.0;
}

.ConstPhoto .PageBreakController:hover{
    opacity: 0.6;
}

.slick-prev:before,
.slick-next:before {
  color: yellow;
}
