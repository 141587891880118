.SafetyInspection{
  position: relative;
  display: block;
  table-layout: fixed;
  width: 100%;
  margin: 5px;
}

.SafetyInspection td {
  padding: 2px;
}

.SafetyInspection thead {
  border-bottom: 1px solid gray;
  color: gray;
}

.SafetyInspection tbody {
  border-bottom: 1px solid gray;
  color: gray;
}

.SafetyInspection td,
.SafetyInspection th {
  table-layout: fixed;
}

.SafetyInspection .text{
  display: flex;
  flex-direction: row;
  width: inherit;
}
