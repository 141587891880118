.Tasks{
    display: block;
    margin: 5px;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .Tasks .ButtonLabel{
        display: none;
    }
}

