.Worker .dataList {
  width: 100%;
  table-layout: fixed;
}

.Worker .scrollHead {
  background-color: #ddd;
}

.Worker .scrollBody {
  background-color: #ddf;
}

/*
* PC用定義
*/
@media screen and (min-width: 481px) {

  .Worker input,
  .Worker textarea {
    width: calc(100% - 5px);
  }

  .Worker .contractLevelInput {
    width: 40px;
  }

  .Worker .companyName {
    width: 140px;
  }

  .Worker .jobType {
    width: 80px;
  }

  .Worker .plannedPersonnel {
    width: 40px;
  }

  .Worker .actualPersonnel {
    width: 40px;
  }

  .Worker .cumulativeProgress {
    width: 40px;
  }

  .Worker .cumulativeProgressInput {
    position: relative;
    display: block;
    width: 32px;
    text-align: right;
    padding-right: 5px;
  }

  .Worker .workDescription {
    width: 240px;
    position: relative;
  }

  .Worker .workDescriptionInput {
    display: block;
    height: 100%;
  }

  .Worker .safetyInstructions {
    width: 240px;
  }

  .Worker .meetingStampField {
    position: relative;
    width: 60px;
  }
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .Worker .companyName {
    width: 100px;
  }

  .Worker .companyNameInput {
    width: 110px;
  }

  .Worker .contractLevelInput {
    width: 40px;
  }

  .Worker .jobTypeInput {
    width: 70px;
  }

  .Worker .plannedPersonnel {
    width: 60px;
  }

  .Worker .plannedPersonnelInput {
    width: 40px;
  }

  .Worker .actualPersonnelInput {
    width: 40px;
  }

  .Worker .cumulativeProgressInput {
    width: 60px;
    text-align: right;
    padding-right: 5px;
  }

  .Worker .workDescription {
    width: auto;
  }

  .Worker .meetingStampField {
    position: relative;
    width: 40px;
  }

  .Worker .meetingStampFieldInput {
    width: 35px;
  }

  .Worker tbody .companyName {
    flex-direction: column;
  }
}