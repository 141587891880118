.WorkReport {
    position: relative;
    display: block;
    top: 0;
    height: auto;
    overflow-x: auto;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: white;
    margin-right: auto;
    margin-left: auto;
}

.WorkReport .workDate {
    display: flex;
    border-top: 3px solid white;
}

.WorkReport .workOvertime {
    display: flex;
    border-top: 3px solid white;
}

.WorkReport .workHeader {
    display: flex;
    border-top: 3px solid white;
}

.WorkReport .workRow {
    display: flex;
    position: relative;
    border-top: 3px solid white;
    min-height: 40px;
}

.WorkReport .constHeader {
    padding-left: 10px;
    padding-right: 10px;
}

.WorkReport .constTitle {
    padding: 3px;
    width: 50px;
    display: block;
    align-items: center;
    /* 縦方向中央揃え */
    justify-content: center;
    /* 横方向中央揃え */
    border-right: 1px solid white;
    background-color: #ddd;
}

.WorkReport .workCell {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.WorkReport .time {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    background-color: #ddf;
}

.WorkReport .timeReadOnly {
    width: 100%;
    padding-right: 15px;
    text-align: right;
}

.WorkReport .timeInput {
    width: 50px;

}

.WorkReport .today {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
    display: flex;
    align-items: center;
    /* 縦方向中央揃え */
    justify-content: center;
    /* 横方向中央揃え */
    background-color: #ddf;
}

.WorkReport .tomorrow {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
    display: flex;
    align-items: center;
    /* 縦方向中央揃え */
    justify-content: center;
    /* 横方向中央揃え */
    background-color: #fdd;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .WorkReport {
        overflow-x: hidden;
    }

    .WorkReport .time {
        text-align: right;
    }

    .WorkReport .timeReadOnly {
        padding-right: 10px;
        text-align: right;
    }

    .WorkReport .timeInput {
        width: 30px;
    }

    .WorkReport .today {
        width: inherit;
        min-width: 300px;
    }

    .WorkReport .tomorrow {
        width: inherit;
        min-width: 300px;
    }

    .WorkReport .workDate {
        width: 100%;
    }

    .WorkReport.workOvertime {
        width: 100%;
    }

    .WorkReport .workRow {
        width: 100%;
    }

    .WorkReport .CellInput {
        max-width: calc(100vw - 30px);
    }
}

.WorkReport .mouseOpe:hover {
    color: #440;
    cursor: pointer;
}

.WorkReport span {
    color: #666;
}