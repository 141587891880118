.ConstStagePlan {
  position: relative;
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin: 2px;
  margin-bottom: 20px;
  top: 0;
  bottom: 0;
  width: 99%;
  height: 100%;
}

.ConstStagePlan .Chart {
  margin-bottom: 5px;
  border: 1px solid #ccc;
  background-color: #ddf;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable {
  position: relative;
  max-width: 100%;
  height: 100px;
  border: none;
  overflow: scroll;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 12px;
}

.ConstStagePlan .th,
.ConstStagePlan .td {
  border: 1px solid #ccc;
  padding: 8px;
  min-width: 100px;
  background-clip: padding-box;
}

.ConstStagePlan .StagePlanTable .HeaderRow {
  margin: 0px;
  background-color: white;
}

.ConstStagePlan .StagePlanTable .HeaderRow th {
  position: sticky;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.ConstStagePlan .StagePlanTable .BodyRow {
  margin: 0px;
  max-height: 20px;
  min-height: 20px;
  height: 20px;
  border-bottom: 1px solid #ccc;
}

.ConstStagePlan .StagePlanTable th,
.ConstStagePlan .StagePlanTable td {
  border: 0.5px solid #ccc;
  padding: 8px;
  min-width: 100px;
}

.ConstStagePlan .StagePlanTable .SummaryRow td {
  background-color: #ddf !important;
}

.ConstStagePlan .StagePlanTable .NameHeader {
  position: sticky;
  left: 0;
  z-index: 6;
  text-align: center;
  background-color: white;
  margin: 0px;
  padding: 5px 0px 5px 0px;
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  font-size: 12px;
  /* 左右のボーダーを個別に設定 */
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.ConstStagePlan .StagePlanTable .PriceHeader {
  position: sticky;
  left: 200px;
  z-index: 6;
  text-align: center;
  background-color: white;
  margin: 0px;
  padding: 0px;
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  font-size: 12px;
  /* 右のボーダーを個別に設定 */
  border-right: 3px solid #aaa;
}

.ConstStagePlan .StagePlanTable .Name {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
  margin: 0px;
  padding: 5px 0px 5px 0px;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable .Price {
  position: sticky;
  left: 200px;
  z-index: 2;
  background-color: white;
  margin: 0px;
  padding: 0px;
  border-right: 3px solid #aaa;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable .Percent {
  margin: 0px;
  padding: 0px;
  border: 1px solid #ccc;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable .PriceParMonth {
  margin: 0px;
  padding: 0px 2px 0px 0px;
  text-align: right;
  border: 1px solid #ccc;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable .Year {
  position: sticky;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable .Month {
  position: sticky;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable .PriceInput {
  margin-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  width: 95%;
  font-size: 12px;
  text-align: right;
}

.ConstStagePlan .StagePlanTable .VolInput {
  margin-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  width: 40px;
  font-size: 12px;
  text-align: right;
}

.ConstStagePlan .StagePlanTable .CellInput {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
}

.ConstStagePlan .StagePlanTable tbody tr:nth-child(odd) td {
  background: #eee;
}