
.ConstIdDialog table{
    position: relative;
    display: block;
    font-family: Meiryo,
        "Hiragino Kaku Gothic Pro",
        "MS PGothic",
        Verdana,
        sans-serif;
    font-size: 12px;
    color: black;
    table-layout: fixed;
    width: 100%;
    background-color: white;
    border: 0px none #fff;
}

.ConstIdDialog td {
  background-color: white;
  padding: 2px
}

.ConstIdDialog thead {
  border-bottom: 2px solid black
}

.ConstIdDialog .scrollHead,
.ConstIdDialog .scrollBody {
  display: block;
}

.ConstIdDialog .scrollBody {
  height: 300px;
  overflow-y: scroll;
}

.ConstIdDialog td,
.ConstIdDialog th {
  table-layout: fixed;
}

.ConstIdDialog .choise{
    width: 70px;
}

.ConstIdDialog .constId{
    width: 80px;
}

.ConstIdDialog .name{
    width: 350px;
    min-width: 350px;
    max-width: 350px;
}
.ConstIdDialog .name div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.ConstIdDialog .city{
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.ConstIdDialog .city div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}
.ConstIdDialog .term{
    width: 250px;
}
.ConstIdDialog .process{
    width: 80px;
}
