.ActionForRisk{
  position: relative;
  display: block;
  table-layout: fixed;
  width: 100%;
  margin: 5px;
}

.ActionForRisk td {
  padding: 2px;
}

.ActionForRisk thead {
  border-bottom: 1px solid gray;
  color: gray;
}

.ActionForRisk .scrollHead,
.ActionForRisk .scrollBody {
  display: block;
}

.ActionForRisk td,
.ActionForRisk th {
  table-layout: fixed;
}

.ActionForRisk .number{
  width: 40px;
}

.ActionForRisk .text{
  display: flex;
  flex-direction: row;
}

.ActionForRisk .evaluation{
  display: flex;
  flex-direction: row;
}

.ActionForRisk .evaluation span{
  white-space: nowrap;
}
