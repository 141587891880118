.Role {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: white;
}

.Role table {
  font-family: Meiryo,
    "Hiragino Kaku Gothic Pro",
    "MS PGothic",
    Verdana,
    sans-serif;
  font-size: 12px;
  color: black;
  width: 100%;
  background-color: white;
  border: 0px none #fff;
}

.Role td {
  background-color: white;
}

.Role thead {
  border-bottom: 2px solid black;
}

.Role td,
.Role th {
  padding: 2px;
  table-layout: fixed;
}

.Role .name {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.Role .groupName {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.Role .check {
  text-align: center;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}