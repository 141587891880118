.ConstReportThumbnail {
   cursor: pointer;
}

.ConstReportThumbnail img {
   min-height: 140px;
   max-height: 140px;
   width: auto;
   border: 1px solid rgba(0, 0, 0, 0);
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
   .ConstReportThumbnail img {
      min-height: 60px;
      max-height: 60px;
   }
}