.ConstManageChoicer .ConstPanel {
  display: flex;
  position: relative;
  margin: 5px 5px 5px 5px;
  padding: 0px;
  width: initial;
  height: initial;
  border: 0px solid gray;
  background-color: white;
  text-align: inherit;
  border-bottom: 1px solid gray;
  padding: 2px;
}

.ConstManageChoicer .ConstImageContainer {
  position: relative;
  display: block;
  width: 150px;
  height: 100px;
}

.ConstManageChoicer .ConstPanel .NoImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100px;
  font-size: 18px;
  font-weight: bold;
  color: lightgray;
  text-align: center;
  vertical-align: middle;
}

.ConstManageChoicer .ConstPanel .ConstImage {
  border-radius: 4px;
  top: 0;
  left: 0;
  width: 150px;
  height: 100px;
  display: block;
  border: 0px solid gray;
  background-color: #888;
}

.ConstManageChoicer .ConstPanel .ConstId {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}

.ConstManageChoicer .ConstPanel .ConstName {
  font-size: 18px;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #666;
}

.ConstManageChoicer .ConstPanel .ConstTerm {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  color: gray;
}

.ConstManageChoicer .ConstPanel .ConstTerm input {
  width: 100px;
}

.ConstManageChoicer .ConstPanel .SalesStaff {
  width: 50%;
  display: inline-block;
}

.ConstManageChoicer .ConstPanel .SalesStaff .Label {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  font-size: 11px;
  color: gray;
}

.ConstManageChoicer .ConstPanel .SalesStaff .Name {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: gray;
}

.ConstManageChoicer .ConstPanel .ConstructionStaff {
  width: 50%;
  display: inline-block;
}

.ConstManageChoicer .ConstPanel .ConstructionStaff .Label {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  font-size: 11px;
  color: gray;
}

.ConstManageChoicer .ConstPanel .ConstructionStaff .Name {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: gray;
}

.ConstManageChoicer .ConstPanel .Process {
  position: absolute;
  top: 80px;
  left: 100px;
  right: initial;
  font-size: 8px;
}