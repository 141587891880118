  .MonthlyTargetVolume .LimitedTextarea {
    font-size: 14px;
    font-weight: bold;
  }

  @media screen and (max-width: 480px) and (orientation:portrait) {
    .MonthlyTargetVolume .LimitedTextarea {
      font-size: 12px;
      font-weight: normal;
    }
  }