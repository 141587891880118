.DropZone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 200px;
  background-color: #eec;
  font-size: 13px;
}

.DropZone:focus {
  outline: 1px solid gray;
}