.Juki {
  position: relative;
  display: block;
  table-layout: fixed;
  width: 100%;
  margin: 5px;
}

.Juki td {
  padding: 2px;
}

.Juki thead {
  border-bottom: 1px solid gray;
  color: gray;
}

.Juki .scrollHead,
.Juki .scrollBody {
  display: block;
}

.Juki td,
.Juki th {
  table-layout: fixed;
}

.Juki .juki {
  width: 320px;
}

.Juki .count {
  width: 40px;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .Juki .juki {
    width: 300px;
  }

  .Juki .count {
    width: 40px;
  }
}