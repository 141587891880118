.LaborCosts {
  position: absolute;
  display: block;
  width: 100%;
  top: 0px;
  bottom: 30px;
  padding: 5px;
  background-color: white;
}

.LaborCosts .control {
  display: block;
  margin: 5px;
  margin-right: 30px;
  height: 30px;
}

.LaborCosts table {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/* 3行ごとにしましまにする */
.LaborCosts table tr:nth-child(6n+4),
.LaborCosts table tr:nth-child(6n+5),
.LaborCosts table tr:nth-child(6n+6) {
  background-color: #eee;
}

.LaborCosts table th {
  text-align: center;
  border-top: 1px solid #ccc;
}

.LaborCosts table thead .actions,
.LaborCosts table thead .jobTitle,
.LaborCosts table thead .user {
  z-index: 5;
}

.LaborCosts table .actions {
  position: sticky;
  left: 0px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  border-left: 1px solid #ccc;
  z-index: 3;
  padding: 0;
  background-color: inherit;
  text-align: center;
}

.LaborCosts table .jobTitle {
  position: sticky;
  left: 50px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  border-right: 1px solid #ccc;
  z-index: 3;
  padding: 0;
  background-color: inherit;
}

.LaborCosts table .user {
  position: sticky;
  left: 200px;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  border-right: 1px solid #ccc;
  z-index: 3;
  padding: 0;
  background-color: inherit;
}

.LaborCosts table tr {
  border-left: 1px solid #ccc;
}

.LaborCosts table th,
.LaborCosts table td {
  padding: 0;
  border-right: 1px solid #ccc;
  height: 15px;
}

.LaborCosts table .budgetPrice {
  min-width: 110px;
}

.LaborCosts table .differencePrice {
  min-width: 110px;
}

.LaborCosts table .terms {
  min-width: 240px;
}

.LaborCosts table .totalOfYear {
  min-width: 110px;
}

.LaborCosts table .totalOEearlyPeriod {
  min-width: 110px;
}

.LaborCosts table .month1st,
.LaborCosts table .month2nd {
  min-width: 80px;
}

.LaborCosts table .total1st,
.LaborCosts table .total2nd {
  min-width: 80px;
}

.LaborCosts table .rowTitle {
  text-align: center;
  min-width: 50px;
}

.LaborCosts table .memo {
  min-width: 300px;
}

.LaborCosts table tr {
  background-color: white;
}

.LaborCosts table th {
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  /* 高さが変わらないよう改行させない */
  white-space: nowrap;
  height: 24px;
  max-height: 24px;
  min-height: 24px;
}

.LaborCosts input {
  border: 1px solid #ccc;
  background-color: transparent;
  margin-left: 5px;
  margin-right: 5px;
}

.LaborCosts table .budgetPrice div {
  width: 100px;
  text-align: right;
}

.LaborCosts table .differencePrice div {
  width: 100px;
  text-align: right;
}

.LaborCosts table .month1st input,
.LaborCosts table .month2nd input {
  width: 70px;
}

.LaborCosts table .memo textarea {
  width: 290px;
  background-color: transparent;
}

.LaborCosts .totalOfYear span,
.LaborCosts .totalOEearlyPeriod span,
.LaborCosts .total1st span,
.LaborCosts .total2nd span,
.LaborCosts .month1st span,
.LaborCosts .month2nd span {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.LaborCosts table thead tr:nth-child(1) th {
  top: 0;
}

.LaborCosts table thead tr:nth-child(2) th {
  top: 24px;
}

.LaborCosts .LaborCostsRow1st {
  max-height: 15px;
}

.LaborCosts .LaborCostsRow td {
  border-right: 1px solid #ccc;
}

.LaborCosts .LaborCostsRow .termFrom,
.LaborCosts .LaborCostsRow .termTo {
  width: 100px;
}

.LaborCosts .LaborCostsRow .totalOfYear,
.LaborCosts .LaborCostsRow .totalOEearlyPeriod,
.LaborCosts .LaborCostsRow .month1st,
.LaborCosts .LaborCostsRow .month2nd,
.LaborCosts .LaborCostsRow .total1st,
.LaborCosts .LaborCostsRow .total2nd {
  text-align: right;
}

.LaborCosts .LaborCostsRow .term {
  display: flex;
}

.LaborCosts .LaborCostsRow .LaborCostsActions {
  opacity: 0.5;
  height: 30px;
  cursor: pointer;
  font-size: 9px;
  transition: opacity 300ms linear;
}

.LaborCosts .LaborCostsRow .LaborCostsActions:hover {
  opacity: 0.8;
}

.LaborCosts .LaborCostsRow .LaborCostsActions button {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0 none;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}

.LaborCosts .LaborCostsRow .LaborCostsActions button:hover {
  opacity: 1.0;
}

.LaborCosts .LaborCostsRow .LaborCostsActions .group-button {
  display: flex;
  align-items: center;
  height: 25px;
  width: 20px;
  /* ボタンの高さ */
}

.LaborCosts .LaborCostsRow .LaborCostsActions .group-button button {
  height: 100%;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
}

.LaborCosts .LaborCostsRow .LaborCostsActions .vertical-buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20px;
}

.LaborCosts .LaborCostsRow .LaborCostsActions .up,
.LaborCosts .LaborCostsRow .LaborCostsActions .down {
  height: 50%;
  max-height: 50%;
  width: 100%;
}

.LaborCosts .LaborCostsRow .LaborCostsActions .up {
  background-image: url("/public/images/up.png");
  background-size: 100%;
}

.LaborCosts .LaborCostsRow .LaborCostsActions .down {
  background-image: url("/public/images/down.png");
  background-size: 100%;
}